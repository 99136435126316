<script lang="ts">
    import '$lib/app.css'

    import { Trackers } from '@nomodo/trackers'
    import type { Snippet } from 'svelte'

    import Footer from '$lib/components/Footer.svelte'
    import SvelteQuery from '$lib/layouts/SvelteQuery.svelte'
    import { initSentry } from '$lib/utils'

    import type { LayoutData } from './$types'

    initSentry()
    const GTM_ID = import.meta.env.VITE_GTM_ID

    interface Props {
        data: LayoutData
        children: Snippet
    }

    const { data, children }: Props = $props()
</script>

{#if GTM_ID}
    <Trackers gtmId={GTM_ID} />
{/if}

<SvelteQuery>
    {@render children()}

    <Footer apps={data.footer.apps} />
</SvelteQuery>
