<script lang="ts">
    import type { Snippet } from 'svelte'
    import type { HTMLAttributes } from 'svelte/elements'

    import Container from '../atoms/Container.svelte'
    import Flex from '../atoms/Flex.svelte'
    import Heading from '../atoms/Heading.svelte'

    interface Props extends HTMLAttributes<HTMLElement> {
        children?: Snippet
    }

    const { class: classNames = '', children, ...rest }: Props = $props()
</script>

<footer class="footer bg-nmd-800 {classNames}" {...rest}>
    <Container>
        <Flex --gap-y="0.5rem">
            <Heading level="6" size="6" useDiv>
                NOMODO.IO/ &nbsp;
                <span class="text-gray-200 before:inline">
                    NO MOre DevOps/
                </span>
                &nbsp;
                <span class=" before:inline">Ready-made apps</span>
            </Heading>
        </Flex>
        {#if children}
            {@render children()}
        {/if}
    </Container>
</footer>

<style global lang="postcss">
    .footer {
        padding: theme('spacing.8') 0;

        @media screen(md) {
            padding: theme('spacing.16') 0;
        }
    }
</style>
